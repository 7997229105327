.section__buscador {
    position: relative;
    &-box {
      width: 100%;
      display: flex;
      align-items: center;
       background-color: #fff;
      &-input {
        flex: 1;
        margin-right: 5px;
        border-radius: 0;
        height: 30px;
        padding-left: 5px
      }
      &-button {
        font-size: 16px;
        border: none;
        outline: none;
        background: #fff;
      }
    }
    &-list {
      position: absolute;
      min-width: 280px;
      max-width: 300px;
      list-style: none;
      background: #fff;
      top: 25px;
      border-radius: 5px;
      box-shadow: 1px 2px 8px 0px #b5b5b5;
      margin: 7px 0;
      z-index: 450;
      overflow: hidden;
      border-bottom: 1px solid #dadbdd;
      border-left: 1px solid #dadbdd;
      border-right: 1px solid #dadbdd;
      &-item {
        border-bottom: 1px solid #ececec;
        &-link {
          color: $color-cambio-color-titulo;
          font-size: 11px;
        }
      }
    }
  }