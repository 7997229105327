.section__appfooter {
  width: 930px;
  border-top: 5px solid $color-cambio-sitio;
  max-width: 100%;
  background-color: #eeeeee;
  padding: 10px;
  
  & h3 {
    font-size: 16px;
    color: #646464;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
    & a {
      cursor: pointer;
      color: #646464;
      text-decoration: none;
    }
  }
  &-box {
    margin-top: 10px;
    // @media (min-width: 768px) {
    //   text-align: center;
    // }

      &-content {
        &-contactos {
        }
        &-clublectores {
        }
        &-newsletter {
          & input[type=button] {
            font-size: 12px;
            background: #023E6A;
            // background: $color-cambio-sitio;
            
            color: #fff;
            border: 1px solid #023E6A;
            // border: 1px solid $color-cambio-sitio;
            height: 30px;
            cursor: pointer;
            margin-top: 2px;
          }
        }
        &-terminos {
          font-size: 10px;
          color: #646464;
          line-height: normal;
          margin-top: 5px;
          & p {
            margin-bottom: 4px;
          }
          & ul {
            margin-left: 10px;
          }
       }
    }
  }

}