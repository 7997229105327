.section__appsubmenu {
  &-box {
    &-content {
      &-submenu {
        color: #fff;
        & a {
          font-size: 0.7rem;
          text-transform: uppercase;
          text-decoration: none;
          padding: 8px 18px;
          margin: 0 8px 8px 0;
          background: $color-cambio-sitio;
          border: 1px solid $color-cambio-sitio;
          color: #fff;
          cursor: pointer;

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      
      }
      
      &-imagen {
        // border: 1px solid #023E6A;
        // padding: 8px 18px;
         width: 140px;
         height:25px;
         margin-bottom: 20px;
         // margin: 0 8px 8px 0;
          cursor: pointer;
       }  



    }
  } 
}


// .section__appsubmenu {
//   &-box {
//     &-content {
//       &-submenu {
//         color: #fff;
//         &-link {
//           font-size: 0.7rem;
//           text-transform: uppercase;
//           text-decoration: none;
//           padding: 8px 18px;
//           margin: 0 8px 8px 0;
//           background: #023E6A;
//           border: 1px solid #023E6A;
//           color: #fff;
//           cursor: pointer;

//           // max-width: 140px;
//           // max-height:25px;
          
//           // @media (max-width: 768px) {
//           //   width: 100%;
//           // }
//         }
//         &-link:hover {
//           color: #fff;
//         }
//         &-imagen {
//           // border: 1px solid #023E6A;
//           // padding: 8px 18px;
//           width: 140px;
//           height:25px;
//           // margin: 0 8px 8px 0;
//           cursor: pointer;
//         }

//         // @media ()
//         //   .container-sm, .container {
//         //   max-width: 540px;
//         // }
//         // @media (min-width: 576px) {
//         //   .container-sm, .container {
//         //      max-width: 100%;
//         //   }  
//         // }

//       }
//     }
//   } 
// }




