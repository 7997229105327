:root {
  --blanco: #FFF;
  --negro: #000;
  --rojo: #dc3545;
  --fondo: #575769;
  --fuentePrincipal: sans-serif,Arial,Helvetica;
}

/* Base */
html {
  box-sizing: border-box;   
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: var(--fuentePrincipal);
  font-size: 14px;
  // width: 1235px;
  color: var(--negro);
}

#app {
  background-color: var(--fondo);
  min-height: 100vh;
}

/* menú */
.header {
    // width: 930px;
  background-color: var(--negro);
  border-bottom: 2px solid var(--rojo);
  height: 50px;
  color: var(--blanco);
  z-index: 5;
}

.header__logo {
  margin-left: 10px;
}

.header__toggler {
  background-color: #fff;
}

.header__collapse {
  background-color: var(--negro);
}

.navegacion {
  margin-left: 20px;
}

.navegacion__link {
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  color: var(--blanco);
}

.navegacion__link:hover {
  color: var(--rojo)   
}

.navegacion__dropdown a{
  color: var(--negro);
}

/* Contenido */
.contenido {
  margin-top: 40px;
}

.contenido__titulo {
  margin-bottom: 34px;
  color: var(--blanco);
  text-align: center;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px gray;
  display: block;
}

.contenido__card {
  color: var(--blanco);
  background-color: var(--negro);
}

.contenido__superior {
  display: flex;
  // justify-content: space-between;
  justify-content: flex-end;
  align-items: flex-end;
}

.contenido__busqueda {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
}

.contenido__busqueda div{
  margin-left: 2px;
}

.contenido__superior label {
  color: var(--blanco);
  margin-bottom: 1px;
}

//Drop zone, corrige problema de recarga de imagen
.dz-image img{
  width: 130px;
  height:130px;
}

.lista-direcciones {
  cursor: pointer;
}

.lista-direcciones:hover {
  background-color: #9c9c9c;
  
}

#publicaciones tbody td {
  vertical-align: middle;
}

.color-fondo-trix {
  background: #E9ECEF;
}

.scroll-verical {
  max-height: 300px !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

.scroll-verical-show {
  max-height: 150px !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}


.form-switch {
  padding-left: 4.5em!important;
}
