.section__appviewcasaclub {
  &-titulo {
    font-family: MetaSerifWeb-Book,sans-serif;
    font-size: 30px;
    font-style: italic;
    color: $color-cambio-color-titulo;
    font-weight: 400;
    margin-bottom: 30px;
    margin-top: 20px;
     border-bottom: 1px solid #8a8a8a;
  }

  &-clubtitulo {
    font-size: 24px;
    margin-bottom: 10px;
  }
}