.section__appsidebar {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 300px;
  }

  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 5rem;
  }

  &-box {
    &-revista{
      text-align: center;
      margin: 5px;
      &-titulo {
        font-size: 1rem;
        color: $color-cambio-color-titulo;
        line-height: 1;
        margin-bottom: 1.5px;
      }
    }
    &-banners {
      margin-top: 20px;
      &-titulo {
        background-color: $color-cambio-sitio;
        color: #fff;
        font-size: 0.8rem;
        line-height:1;
        padding: 5px;
      }
    }
  }
}
