.section__apppublicacionesrelacionadas {
    width: 100%;
    &-titulo {
      color: $color-cambio-color-titulo;
      font-style: italic;
      font-family: MetaSerifWeb-Book,serif;
      text-transform: none;
      margin-bottom: 15px;
      font-size: 23px;
      font-weight: 400;
      text-align: center;
    }
    &-card {
      border: 0;
      &-title {
        font-family: MetaSerifWeb-Book,sans-serif;
        color: $color-cambio-color-titulo;
        font-style: italic;
        font-weight: 400;
        font-size: 17px;
        max-height: 55px;
        margin-bottom: 0;
        padding-bottom: 0;
        text-align: left;
        line-height: 1;
      }
      &-subtitulo {
        font-family: MetaHeadlineWeb,sans-serif;
        color: #646464;
        line-height: 17px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 20px;
        text-align: left;
      }
    }
    & a {
      opacity: 1
    }
  
    & a:hover {
      opacity: 0.8
    }
  }
  