// Body
// $body-bg: #f8fafc;
:root {
    --config-color-sitio: #000000;
    --config-color-titulo: #000000;
    --config-color-titulo-hover: #000000;
    --config-color-publicacion-titulo: #000000;
}
// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$color-cambio-sitio: var(--config-color-sitio);
$color-cambio-color-titulo: var(--config-color-titulo);
$color-cambio-color-titulo-hover: var(--config-color-titulo-hover);
$color-cambio-color-publicacion-titulo: var(--config-color-publicacion-titulo);

