.position-relative {
  position: relative !important;
}

.box-central {
  position: relative;
  width: 300px;
  right: 0px;
  z-index: 4;
  padding-top: 1rem;
}    

.box-central-detalle {
  position: relative;
  width: 300px;
  right: 0px;
  z-index: 4;
  padding-top: 3rem;
}    

.banner-fixed-container {
  // margin-top: 27px;
  width: 300px;
  height: 100%;
  position: absolute;
  // right: 0;
  left: 932px;
  top: 0;
  z-index: 4;
}

.banner-fixed-container .banner-wrapper-floting {
  position: -webkit-sticky;
  position: sticky;
  margin: 0;
  text-align: right;
}

.banner-fixed-container  .banner-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}

.banner-fixed-container-static {
  width: 300px;
  position: relative;
  padding-top: 162px;
  z-index: 500;
}

.session-activa {
  padding-top: 189px;
}

.slide__banner {
  top: 0;
  left: 932px;
  position: absolute;
  width: 100%;
  max-width: 300px;
  margin-left: 5px;
}

.superior {
  position: relative;
  width: 300px;
  right: 0px;
  z-index: 4;
  padding-bottom: 5rem;
  padding-top: 20.1rem;
}

.home-banner {
   width: 710px;
}

.pub-sidebar {
  position: relative;
  max-width: 930px;
  width: 100%;
  margin: 5px 0;
}

.pub-sidebar .cerrar_on_random {
  width: 100% !important;
}

.pub-sidebar img, .home-banner img {
  max-width: 100%;
}

.movil {
  position: relative;
  max-width: 666px !important;
  width: 100%;
  margin: 5px 0;
  z-index: 9;
}

.itt {
  margin: 0px;
  padding: 0;
  position: absolute;
}


