.section__appmodallogin {
    background: rgba(0,0,0,.7);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1001;
    &-content {
      position: fixed;
      display: flex;
      flex-direction: column;
      width: 530px;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0,0,0,.2);
      border-radius: 0.3rem;
      outline: 0;
      top: 5%;
      z-index: 1001;
      left: 50%;
      margin-left: -285px;

       &-header {
        padding: 15px 17px 14px;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
     }
     &-body {
      position: relative;
      flex: 1 1 auto;
      padding: 1rem;
      &-tab{
        float: left;
        width: 100%;
        font-size: 12px;
        margin-bottom: 5px;
        & a {
          float: left;
          width: 49%;
          padding: 10px 0;
          background:$color-cambio-color-titulo;
          border: 1px solid #d3d3d3;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
          text-decoration: none;
          cursor: pointer;
        }
        &--activo {
          background: $color-cambio-color-titulo-hover !important;
          color: #fff !important;
        }
      }
     }
     &-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 1rem;
      border-top: 1px solid #e9ecef;
     }
  } 
  &-login {
    float: left;
    width: 100%;
    font-size: 12px;
    margin-top: 20px;
    &-content {
      float: left;
      width: 100%;
      padding: 0;
      &-input {
        float: left;
        width: 98%;
        padding: 0;
        margin-bottom: 5px;
        border: 1px solid #b4c1ce;
        outline: none;
        &-icon {
          position: relative;
          left: 6px;
          width: 16px;
          font-size: 14px;
          top: 0;
          color: #7f7f80;
        }
        &-text {
          width: 92%;
          height: 28px;
          border: 0;
          padding: 0;
          background-color: transparent;
          font-size: 12px;
          position: relative;
          left: 17px;
          border-color: transparent;
          box-shadow: none;
          outline: none;
        }
      }
      &-recover {
        &-textbox {
          font-size: 11px;
          color: #d04b47;
          float: left;
          width: 100%;
          padding: 10px 0 10px 10px;
        }
      }
      &-olvpass {
        float: left;
        margin-top: 10px;
      }
      &-errorsmall {
        font-size: 11px;
        color: #d04b47;
        padding: 10px 0 0 12px;
        float: left;
        margin-bottom: 10px;
        width: 100%;
        margin-left: 10px;
      }
    }
    &-button {
      font-family: Arial,Geneva,sans-serif;
      margin-top: 15px;
      float: left;
      width: 100%;
      height: 31px;
      color: #fff;
      border-radius: 0;
      text-align: center;
      font-size: 10px;
      text-transform: uppercase;
      &-pram {
        background: $color-cambio-color-titulo;
        border-color:$color-cambio-color-titulo;
        width: 145px;
        padding: 9px 0 8px 1px;
         cursor: pointer;
      }
      &-cancel {
        background: #023e69;
        border-color: #023e69;
        width: 145px;
        padding: 9px 0 8px 1px;
        cursor: pointer;
      }
    }
  }
}