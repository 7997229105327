.section__appviewpublicaciones {
  max-width: 930px;
  &-content {
    color: $color-cambio-color-titulo;
    line-height: 0.8;
    cursor: pointer;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    
    &-logo {
      max-width: 160px;
      width:40px;
      height:40px;
      border-radius: 50%;
      margin-right: 10px;
    }
    &-icono {
      max-width: 160px;
      width:140px;
      height:25px;
      //border-radius: 50%;
      margin-left: 10px;
    }
    &-titulo {
      color: $color-cambio-color-titulo !important;
      font-size: 20px;
      font-weight: 600;
      font-family: MetaSerifWeb-Book, serif;
      font-style: italic;
      text-transform: capitalize;
    }
    &-card {
    
      &-lista {
        padding-left: 10px;
        overflow: hidden;
        list-style: none;
        & li {
          margin-bottom: 0px;
          @include media-breakpoint-up(md) {
            margin-bottom: 15px;
         }
          & a {
            display: block;
            text-decoration: none;
            opacity: 1;
          }
          & a:hover {
            opacity: 0.8;
          }
        }
      }
      &-imagen {
        border: 1px solid #f3f3f3;
        display: block;
        overflow: hidden;
        line-height: 0;
      }
          
      &-tipo {
        color: $color-cambio-color-titulo;
        font-size: 12px;
        font-weight: 600;
        font-family: MetaSerifWeb-Book, serif;
        font-style: italic;
        padding-bottom: 2px;
      }
      &-tipomonto {
        color: $color-cambio-color-titulo;
        font-size: 12px;
        font-weight: bold;
      }
      &-monto {
        color: $color-cambio-color-titulo;
        font-size: 18px;
        font-weight: bold;
      }
      &-titulo {
        color: $color-cambio-color-titulo;
        font-weight: 600;
        font-family: MetaSerifWeb-Book, serif;
        font-style: italic;
        padding-bottom: 3px;
      }
      &-subtitulo {
        font-size: 12px;
        color: #000;
        
      }
      & p {
        margin-bottom: 0;
      }
      &-errorbusqueda {
        margin-bottom: 20px;
        font-size: 17px;
        color: #444;
        text-transform: uppercase;
        background-color:blue;
      } 
      &-errormensaje_1 {
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
        background-color:red;
      }
      &-errormensaje_2 {
        margin-bottom: 20px;
        background-color:yellow;
      }
    }
    &-encontrado {
      color: #858585;
      font-family: MetaSerifWeb-Book,serif;
      font-size: 36px;
      margin-bottom: 10px;
      margin-top: 10px;
      &-texto {
        color: #858585;
        font-size: 16px;
        margin-bottom: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
