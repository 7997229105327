.section__appmenu {
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 930px;
  }
  &-box {
    &-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &-menu {
        padding-left: 0px;
        text-align: left;
        @include media-breakpoint-up(md) {
            text-align: center;
        }
      & li {
        cursor: pointer;
        border-top: 1px solid #E0DFDF;
        @include media-breakpoint-up(md) {
            border-top: 0px solid #E0DFDF;
        }
        list-style: none;
        @include media-breakpoint-up(md) {
            display: inline-block;
        }
         & a {
            color: $color-cambio-color-titulo !important;
            
            font-size: 0.8rem;
            text-transform: uppercase;
            text-decoration: none;
            padding: 5px 10px 5px 10px;
            display: block;
          }
          & a:hover {
            color: $color-cambio-color-titulo-hover !important;
          }
        }
      }
    }
  }

  .active {
    color: $color-cambio-color-titulo-hover !important;
  }
}
