.section__appviewPublicacion {
  width: 930px;
  &-titulo {
    font-style: italic;
    // color: #023E6A;
    // color: $config-color-publicacion-titulo !important;
    color: $color-cambio-color-publicacion-titulo;
    font-weight: 400;
  }
  &-bajada {
    font-size: 15px;
    color: #444;
    margin-top: 3px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #8a8a8a;
  }
  &-box {
      &-carrousel {
      }
      &-content {
        &-redes {
          display: flex;
          align-items: center;
          justify-content: center;
          list-style-type: none;
          padding-left: 0;
          margin-top: 0px;
   
          @include media-breakpoint-up(xs) {
            margin-top: 30px;
          }
          
          @include media-breakpoint-up(sm) {
            margin-top: 30px;
          }

          @include media-breakpoint-up(md) {
              justify-content: start;
              margin-top: 0px;
          }
        
          & li {
            margin-left:0.5rem;
            margin-bottom: 5px;
            padding-bottom: 5px;
          }
          border-bottom: 1px solid #8a8a8a;
        }
      &-contacto {
        &-titulo {
          font-family: MetaSerifWeb-Book,sans-serif;
          font-style: italic;
          color: #023E6A;
          font-weight: 400;
        }
        &-descripcion {
          font-size: 12px;
        }
        &-informacion {
          & div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
            & div {
              margin-bottom: 0px;
            }
          }
          & a {
            color: #023E6A;
            cursor: pointer;
          }
          }
        }
      &-mapa {
        width: 100%;
        & a {
            cursor: pointer;
        }
      }
    }
    &-parrafo {
      padding-top: 20px;
    }
  }
}