// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "external/trix.css";

@import "main/main-admin";
@import "main/banner";
@import "main/header";
@import "main/variables";
@import "main/themes";
@import "main/menu";
@import "main/footer";

@import "main/publicacionesdestacadas";
@import "main/publicacionesrelacionadas";
@import "main/viewiniciocontent";
@import "main/sidebar";
@import "main/viewcontentprincipal";
@import "main/submenu";
@import "main/viewpublicaciones";

@import "main/viewPublicacion";

@import "main/viewcasaclub";
@import "main/_viewDudaConsulta";

@import "main/main";
@import "main/paginacion";
@import "main/buscador";
@import "main/modallogin";
@import "main/viewbusqueda";











