.section__appviewdudaconsulta {
  &-titulo {
    font-family: MetaSerifWeb-Book,sans-serif;
    font-style: italic;
    font-size: 22px;
    color: $color-cambio-color-titulo;
    font-weight: 400;
    padding-bottom: 10px;
    border-bottom: 1px solid #8a8a8a;
  }
  &-button {
    font-size: 12px;
    color: $color-cambio-color-titulo;

    color: #fff;
    color: $color-cambio-color-titulo;
    cursor: pointer;
    margin-top: 2px;
    padding: 10px 20px;
  }
  &-button--disabled {
    font-size: 12px;
    background: #577e9b;
    color: #fff;
    border: 1px solid $color-cambio-color-titulo;
    cursor: pointer;
    margin-top: 2px;
    padding: 10px 20px;
  }
 
}

a {
  color: #023E6A;
}

.error-msg {
  font-size:12px;
  color:#fe504f;;
}